import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Layout, Avatar, Radio, Drawer, Badge } from "antd";
import { Link, useHistory } from "react-router-dom";
import img from "./images/profile.png";
import { IoIosArrowDown } from "react-icons/io";
import { BsFillBellFill } from "react-icons/bs";
import noimage from "./images/no-image.jpg";

import { motion, AnimatePresence } from "framer-motion";
import { selectAuth, getLng } from "../global/authSlice";
import { useAppDispatch, useAppSelector } from "../global/hooks";
import { useTranslation } from "react-i18next";
import {
  onErrorMsg,
  onSuccessfetchMsg,
  onSuccessMsg,
} from "../components/shared/Message";
import { useFetchAll, useFetchOne } from "../api/useApihook";
import Truncate from "./shared/truncate";
import NotificationDrawer from "./notificationDrawer";
import key from "../key";

const { Header } = Layout;

function HeaderSec({ title, children }: any) {
  const [profileMenu, openProfileMenu] = useState({ main: false, sub: false });
  const { user } = useAppSelector(selectAuth);
  const tg = useRef() as any;
  const [visible, setVisible] = useState(false);

  const { lng } = useAppSelector(selectAuth);
  const { i18n } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (profileMenu.main) {
      document.addEventListener("click", outerClickHandler);
    }
    return () => document.removeEventListener("click", outerClickHandler);
  }, [profileMenu.main]);

  function outerClickHandler(ev: any) {
    if (tg.current && !tg.current.contains(ev.target)) {
      openProfileMenu({ main: false, sub: false });
    }
  }

  const onError = ({ response }: any) => onErrorMsg(response);
  const { data, isLoading } = useFetchOne(
    "profile-details",
    onSuccessfetchMsg,
    onError
  );


  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleLng = (e: any) => {
    dispatch(getLng(e.target.value));
    i18n.changeLanguage(e.target.value);
  };

  const openDrawer = useCallback(() => setVisible(true), []);
  const closeDrawer = useCallback(() => setVisible(false), []);
  return (
    <HeaderWrap>
      <Header
        className="header  grid grid-cols-9 "
        style={{ zIndex: 1, width: "100%" }}
      >
        <div className="col-span-8 flex justify-start items-center">
          <h2 className="text-xl capitalize font-bold">{title}</h2>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {children}
        </div>

        <div className="col-span-1 flex justify-end items-center cursor-pointer">
          <div onClick={openDrawer}>
            <Badge
              className=""
              count={user?.notificationCount}
              color="#f3b50c"
              size="default"
            >
              <BsFillBellFill
                style={{ color: "#1E1E1E" }}
                className=" cursor-pointer text-lg"
              />
            </Badge>
          </div>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <div
            className="col-span-1 flex justify-end items-center cursor-pointer"
            onClick={() =>
              openProfileMenu({ ...profileMenu, main: !profileMenu.main })
            }
          >
            <Avatar
              src={
                <img
                  alt=""
                  src={
                    data?.profile_pic
                      ? key.BACKEND_FILE_URL + data?.profile_pic
                      : noimage
                  }
                  style={{ width: 43 }}
                />
              }
            />{" "}
            &nbsp;&nbsp;
            <IoIosArrowDown
              className=" cursor-pointer"
              style={profileMenu.main ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
        </div>
      </Header>
      {profileMenu.main && (
        <AnimatePresence>
          <ProfileMenu
            ref={tg}
            className="shadow-2xl p-2 text-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.36, type: "tween", ease: "easeInOut" }}
          >
            <a style={{ color: "#1E1E1E" }} href="/dashboard/profile">
              <div className="item">Profile information </div>
            </a>

            <a style={{ color: "#1E1E1E" }} href="/dashboard/change-password">
              <div className="item">Change password</div>
            </a>

            <a style={{ color: "#1E1E1E" }} href="/dashboard/food911details">
              <div className="item">Food 9-11 details </div>
            </a>
            <div className="items">
              <div
                className="shower flex justify-between items-center"
                onClick={() =>
                  openProfileMenu({ ...profileMenu, sub: !profileMenu.sub })
                }
              >
                <p style={{ fontSize: "12px", fontWeight: 500 }}>Language</p>
                <p
                  style={profileMenu.sub ? { transform: "rotate(180deg)" } : {}}
                >
                  <IoIosArrowDown className=" cursor-pointer " />
                </p>
              </div>
              {profileMenu.sub && (
                <Radio.Group className="grid" onChange={handleLng} value={lng}>
                  <Radio value="en">English</Radio>
                  <Radio value="ru">Russian</Radio>
                  <Radio value="lt">Lithuanian</Radio>
                </Radio.Group>
              )}
            </div>
            <div className="item text-red-500" onClick={handleLogout}>
              Logout
            </div>
          </ProfileMenu>
        </AnimatePresence>
      )}
      <NotificationDrawer visible={visible} closeDrawer={closeDrawer} />
    </HeaderWrap>
  );
}

export default React.memo(HeaderSec);

const HeaderWrap = styled.div`
  .ant-layout-header {
    line-height: 2px !important;
  }
  position: sticky !important;
  top: 0;
  z-index: 2;
  .header {
    box-shadow: 0 1px 3px -1px rgb(0 0 0 / 10%);
    /* border-bottom: 1px solid #d4d4d4; */
    background-color: white;
    padding: auto 70 !important;

    .ant-select-selector {
      border-color: transparent !important;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      box-shadow: 0 0 0 0px transparent !important;
    }
  }
  .trigger {
    font-size: 1.2rem;
    transform: translateX(0.3rem);
  }
`;

const ProfileMenu = styled(motion.div)`
  cursor: pointer;
  background: #ffffff;
  overflow: hidden;
  min-width: 201px;
  border-radius: 11px;
  position: absolute;
  top: 63px;
  right: 47px;
  z-index: 1;
  display: grid;
  gap: 2px;
  .item {
    width: 100%;
    padding: 5px 8px;
    font-size: 12px;
    line-height: 22px;
    font-weight: 500;
  }
  .items {
    padding: 5px 8px;
  }
  .ant-radio-group {
    display: grid;
    gap: 2px;
    padding-top: 7px;
  }
`;
