import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import ReactDOM from "react-dom";

export default function AppBackDrop(props: any) {
  const { children, ...restProps } = props;
  const modal = document.getElementById("modal")!;
  const ref = useRef() as any;
  const [flag, toggleFlag] = useState(false);
  useEffect(() => {
    toggleFlag(!flag);
  }, []);

  useEffect(() => {
    if (flag) {
      document.addEventListener("click", outerClickHandler);
      return () => document.removeEventListener("click", outerClickHandler);
    }
  }, [flag]);

  function outerClickHandler(ev: any) {
    if (ref.current && !ref.current.contains(ev.target)) {
      restProps.close();
    }
  }
  const variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: {
      duration: 0.36,
      type: "tween",
      ease: "easeInOut",
    },
  };

  return ReactDOM.createPortal(
    <AnimatePresence>
      <Shadow {...variants}>
        <div ref={ref}>{React.cloneElement(children, { ...restProps })}</div>
      </Shadow>
    </AnimatePresence>,
    modal
  );
}

const Shadow = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  display: grid;
  place-items: center;
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);
`;
