export function asyncAuth() {

    let token =  localStorage.getItem('sfclienttoken') ? localStorage.getItem('sfclienttoken')  : null
    let sub =  localStorage.getItem('notify') ? localStorage.getItem('notify')  : null

    
    return new Promise((resolve) =>

      setTimeout(() => resolve({token, sub}), 1000)
    );
  }