import React from "react";
import Header from "../../header";
import Orders from "./orders";
import Invoice from "./invoice";
import { Button } from "antd";
import MSExcel from "../../images/MSExcel.svg";
export default function Index() {
  return (
    <div>
     

        <Orders />
    </div>
  );
}
