import React from 'react'
import {Spin} from 'antd'
import Backgroudui from './backgroudui'
import logo from '../images/logo1.png'
import BarLoader from "react-spinners/PropagateLoader";

export default function Loader() {
    return<main className="px-12 py-5" > 
    <Backgroudui >
<div className="flex  justify-center items-center" style={{height:"85vh"}}>
<div>
<img style={{background:"#0000001f"}} src={logo} alt="logo" className=" transform -translate-x-7 p-2 rounded-md" />
<br/>
<BarLoader color="#929292"  />
</div>
</div>
</Backgroudui>
</main>

    
}
