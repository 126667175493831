import { Tooltip } from "antd";
import React from "react";
import styled from "styled-components";
import Truncate from "../../../shared/truncate";

export default function Address({address}: any) {

  const {billing_address, shipping_address} = address

  return (
    <AddressWrap className=" grid grid-cols-2 gap-8">
      <div className="">
        <h1 className="title">Billing Address</h1>
        <hr />
        <br />
        <ul>
          <li>
            <p className="item">full name</p>
            <p className="price">{billing_address?.customer_name}</p>
          </li>
          <li>
            <p className="item">Address</p>
            <p className="price text-right">
           <Tooltip title={billing_address?.address}>

           { Truncate(billing_address?.address, 40)}

           </Tooltip>
            </p>
          </li>

          <li>
            <p className="item">City</p>
            <p className="price">{billing_address?.city_name}</p>
          </li>
          <li>
            <p className="item">ZIP/Post Code</p>
            <p className="price">{billing_address?.zip_code}</p>
          </li>

          <li>
            <p className="item">Phone</p>
            <p className="price">{billing_address?.customer_phone_number}</p>
          </li>
        </ul>
      </div>
      <div className="">
        <h1 className="title">Shipping Address</h1>
        <hr />
        <br />
        <ul>
          <li>
            <p className="item">full name</p>
            <p className="price">{shipping_address?.customer_name}</p>
          </li>
          <li>
            <p className="item">Address</p>
            <p className="price text-right">
            <Tooltip title={shipping_address?.address}>

{ Truncate(shipping_address?.address, 40)}

</Tooltip>
            </p>
          </li>

          <li>
            <p className="item">City</p>
            <p className="price">{shipping_address?.city_name}</p>
          </li>
          <li>
            <p className="item">ZIP/Post Code</p>
            <p className="price">{shipping_address?.zip_code}</p>
          </li>

          <li>
            <p className="item">Phone</p>
            <p className="price">{shipping_address?.customer_phone_number}</p>
          </li>
        </ul>
      </div>
    </AddressWrap>
  );
}

const AddressWrap = styled.div`
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price {
      font-weight: 500;
      line-height: 2;
    }
    .item {
      font-size: 11px;
      line-height: 22px;
      color: #1e1e1e;

      opacity: 0.8;
    }
  }
`;
