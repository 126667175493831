import React, { useEffect, useState } from "react";
import BackgroundUi from "../../shared/backgroudui";
import { Table, Tag, Space, Button, Pagination, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { useFetchAll, useFetchOne } from "../../../api/useApihook";
import { onErrorMsg, onSuccessfetchMsg } from "../../shared/Message";
import { useAppSelector } from "../../../global/hooks";
import { selectAuth } from "../../../global/authSlice";
import {FaAngleDown} from 'react-icons/fa'
import MSExcel from "../../images/MSExcel.svg";
import { DownloadOutlined } from "@ant-design/icons";
import Loader from "../../shared/loader";
import TableLoading from "../../shared/tableLoading";
import Header from "../../header";
import ExcelExport from "../../shared/excel";
import caleicon from '../../images/calender.png';
import timeicon from '../../images/time.png';
import Truncate from "../../shared/truncate";

export default function Orders() {
  const history = useHistory();
  const {user, default_restaurant_id} = useAppSelector(selectAuth);
  const [page, setPage] = useState(1) as any

const onSuccess = () =>onSuccessfetchMsg()
const onError = ({response}:any)=>onErrorMsg(response)

  const { data:list, isLoading} = useFetchAll(`order-list/${default_restaurant_id}?${page}&start_date=desc`, onSuccess, onError)
  const [downloadId, setDownloadId] = useState() as any;
  const [filterValue, setFilterValue] = useState('All') as any;
  const [filterValueCity, setFilterValueCity] = useState('All') as any;
  const [filterValueCid, setFilterValueCid] = useState('All') as any;

  const [sort, setSort] = useState(false) as any;


  const { data: country_list, isLoading: LoadingCountry } = useFetchAll(
    `country-list`,
    onSuccessfetchMsg,
    onError
  );



  let countries =  country_list && country_list?.country_list?.map((item:any)=>{

    return {
      text:item?.country_name,
      value:item?.id
    }
  })
  
useEffect(()=>{

  setPage(`page=1&per_page=10&vehicle_mode=${filterValue}&country_id=${filterValueCid}&start_date=${sort? 'desc': 'asc'}`)


}, [sort])
  


const {
  data: invoice,
  refetch: fetchInvoice,
  isLoading: inVoiceLoading,
} = useFetchOne(
  `order-invoice/${downloadId}`,
  (value: any) => {
    console.log(value);
    window.open(`https://food911.php-dev.in/${value.invoice_pdf}`, "_blank");
    setDownloadId();
  },
  onError,
  {
    enabled: !!downloadId,
  }
);

const dwnld = (ev: any, order_id: any) => {
  ev.stopPropagation();
  setDownloadId(order_id.split("#")[1]);
};




const  handleFilter =(value: any, record: any)  =>{

  
  
  if(value === 'all') {

    setFilterValue('All')
    setPage(`page=1&per_page=10&vehicle_mode=All&country_id=${filterValueCid}&start_date=${sort? 'desc': 'asc'}`)

    return record

  }
  setFilterValue(value)
  setPage(`page=1&per_page=10&vehicle_mode=${value}&country_id=${filterValueCid}&start_date=${sort? 'desc': 'asc'}`)
return list?.order_list?.data
}


const handleFilterRating =(value: any, record: any)  =>{

  
  
  if(value === 'all') {

    setFilterValue('All')
    setPage(`page=1&per_page=10&vehicle_mode=All&rating=${value}&country_id=${filterValueCid}&start_date=${sort? 'desc': 'asc'}`)

    return record

  }
  setFilterValue(value)
  setPage(`page=1&per_page=10&vehicle_mode=All&rating=${value}&country_id=${filterValueCid}&start_date=${sort? 'desc': 'asc'}`)
return list?.order_list?.data
}

const handleFilterCity =(value: any, record: any)  =>{

  
  
  // if(value === 'all') {

  //   setFilterValue('All')
  //   setPage(`page=1&per_page=10&vehicle_mode=all`)

  //   return record

  // }
let filtervalue = country_list && countries?.find((item:any) => item.value == value)  
  

if(value != 'All'){

  setFilterValueCity(filtervalue?.text)


} else {

  setFilterValueCity('All')

}
setFilterValueCid(value)


  setPage(`page=1&per_page=10&country_id=${value}&vehicle_mode=${filterValue}&start_date=${sort? 'desc': 'asc'}`)
return list?.order_list?.data
}


  const columns = [
    {
      title: ()=><p>Order Ids</p>,
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: () => "Order Status",
      dataIndex: "order_staus",
      key: "order_staus",
      render: (text: any) => (
        <div
          style={{
            color: text == 'Process' ? "#8d1f71":
            (text == 'Pending')? '#ce8c00' :(text == 'Delivered')?
            '#01f563' : (text == 'Ready For Delivery') ?
            '#dd6e06' : (text == 'Order Picked') ? '#0275b8' : '#9e2323',
            background:text == 'Process' ? "#ffebfa":
             (text == 'Pending')? '#ffefcd' :(text == 'Delivered')?
             '#b8fdd4' : (text == 'Ready For Delivery') ?
             '#ffe1c7' : (text == 'Order Picked') ? '#ddeefd' : '#fddedd' ,
            borderRadius: "11px",
            textAlign: "center",
            width: "100%",
            padding: "5px 0.9rem",
          }}
        >
          {text}
        </div>
      ),
    },

    {
      title: "Order placed",
      dataIndex: "order_placed",
      key: "order_placed",
    },
    {
      title: "city",
      dataIndex: "city",
      key: "city",
      filters:countries? [{text:'All', value:'All'}, ...countries] : [],
      filterIcon:()=><div className="flex items-center">&nbsp; &nbsp;{filterValueCity} &nbsp; <FaAngleDown className="text-md"/></div> ,
      onFilter: handleFilterCity ,
      filterMultiple:false 
    },

    {
      title: "address",
      dataIndex: "billing_address",
      key: "billing_address",
      render:(bil_adress:any)=>{

        return <p>{bil_adress?.address}</p>
      }
    },
    {
      title: "Customer name",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Phone number",
      dataIndex: "customer_phone_number",
      key: "customer_phone_number",
    },
    {
      title: <p className="flex items-center gap-x-2"> <img src={caleicon} alt="" /> start date</p>,
      dataIndex: "start_date",
      key: "start_date",
      sorter: (a:any, b:any) => setSort(!sort),



    },
    {
      title: <p className="flex items-center gap-x-2"> <img src={timeicon} alt="" /> start time</p>,
      dataIndex: "start_time",
      key: "start_time",
    },
    {
      title: "payment type",
      dataIndex: "payment_type",
      key: "payment_type",
    },
    {
      title: "duration(min)",
      dataIndex: "duration_time",
      render:(value:any)=><p>{value} min</p>,

      key: "duration_time",
    },
    {
      title: "Restaurant name",
      dataIndex: "restaurant_name",
      key: "restaurant_name",
    },
    {
      title: "Delivery partner name",
      dataIndex: "delivery_partner_name",
      key: "delivery_partner_name",
    },
   
    {
      title: "Delivery Partner Mobile Number",
      dataIndex: "delivery_partner_mobile_no",
      key: "partner_phone_number",
    },
    {
      title: "Vehicle mode",
      dataIndex: "vehicle_mode",
      key: "vehicle_mode",
      filters: [
        {
          text: "All",
          value: 'all',
        },
        {
          text: "Car",
          value: 'car',
        },
        {
          text: "bike",
          value: 'bike',
        },
      ],
      filterIcon:()=><div className="flex items-center">&nbsp; &nbsp;{filterValue} &nbsp; <FaAngleDown className="text-md"/></div> ,
      onFilter: handleFilter ,
      filterMultiple:false    
    },
    {
      title: "Delivery partner earnings",
      dataIndex: "delivery_partner_earning",
      key: "delivery_partner_earning",
    },
    {
      title: "Restaurant earnings",
      dataIndex: "restaurant_earnings",
      key: "restaurant_earnings",
    }, 
    {
      title: "Food911 earning",
      dataIndex: "food911_earning",
      key: "food911_earning",
    },
    {
      title: "Food911 credit used",
      dataIndex: "food911_credit_used",
      key: "food911_credit_used",
    },
    {
      title: "Refund",
      dataIndex: "refund",
      key: "refund",
    },
    {
      title: "Total final price",
      dataIndex: "total_price_adding_vat_packing_fee",
      key: "total_price_adding_vat_packing_fee",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "Rating",
      filters: [
        {
          text: "All",
          value: 'All',
        },
        {
          text: "1",
          value: '1',
        },
        {
          text: "2",
          value: '2',
        },
        {
          text: "3",
          value: '3',
        },
        {
          text: "4",
          value: '4',
        },
        {
          text: "5",
          value: '5',
        },
      ],
      filterIcon:()=><div className="flex items-center">&nbsp; &nbsp;{filterValue} &nbsp; <FaAngleDown className="text-md"/></div> ,
      onFilter: handleFilterRating ,
      filterMultiple:false
    },
    {
      title: "Review",
      dataIndex: "review",
      key: "Review",
      render:(text:any = "no review") => <p> <Tooltip title={text|| ""}>{Truncate(text || "-", 20)}</Tooltip> </p>

    },
    // {
    //   title: "Invoice",
    //   key: "invoice",
    //   render: (invoice:any) =><p onClick={handleInvoice} >invoice</p>
    // },
    {
      title: () => <p className="w-max">Invoice</p>,
      dataIndex: "invoice",
      key: "order_invoice",
      render: (text: any, { order_id }: any) => {
        return order_id.split("#")[1] === downloadId ? "Loading...": (
          <Button
            onClick={(ev: any) => dwnld(ev, order_id)}
            type="link"
            icon={<DownloadOutlined />}
          />
        );
      },
    }
  ];



const handleChange = (page:any) =>{

  
  setPage(`page=${page?.current}&per_page=${page?.pageSize}&vehicle_mode=all`)
  

}


const eColumn = [
  {
    title: "Id",
    dataIndex: "order_id",
  },
  {
    title: "Status",
    dataIndex: "order_staus",
  },
  {
    title: "Order Placed",
    dataIndex: "order_placed",
  },
  {
    title: "City",
    dataIndex: "city",
  },
  {
    title: "Customer Name",
    dataIndex: "customer_name",
  },
  {
    title: "Customer Phone Number",
    dataIndex: "customer_phone_number",
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
  },
  {
    title: "Start Time",
    dataIndex: "start_time",
  },
  {
    title: "Delivery Type",
    dataIndex: "order_delivery_type",
  },
  {
    title: "Delivery Time",
    dataIndex: "order_delivery_time",
  },
  {
    title: "Payment Type",
    dataIndex: "payment_type",
  },
  {
    title: "Duration Time",
    dataIndex: "duration_time",
  },
  {
    title: "Distance",
    dataIndex: "distance",
  },
  {
    title: "Restaurant Name",
    dataIndex: "restaurant_name",
  },
  {
    title: "Delivery Partner Name",
    dataIndex: "delivery_partner_name",
  },
  {
    title: "Delivery Partner Mobile Number",
    dataIndex: "delivery_partner_mobile_no",
  },
  {
    title: "Vehical Mode",
    dataIndex: "vehicle_mode",
  },
  {
    title: "Food911 Earning",
    dataIndex: "food911_earning",
  },
  {
    title: "Restaurant Settlement",
    dataIndex: "restaurant_settlement",
  },
  {
    title: "Delivery Partner Settlement",
    dataIndex: "delivery_partner_settlement",
  },
  {
    title: "Amount Without VAT",
    dataIndex: "amount_without_vat",
  },
  {
    title: "Order Total Price",
    dataIndex: "order_total_price",
  },
  {
    title: "Vat Amount",
    dataIndex: "vat_amount",
  },
  {
    title: "Packing Fee",
    dataIndex: "packing_fee",
  },
  {
    title: "Total VAT Fee",
    dataIndex: "total_price_adding_vat_packing_fee",
  },
  {
    title: "Food911 Credit Earnings",
    dataIndex: "food911_credit_earning",
  },
  {
    title: "Food911 Credit Used",
    dataIndex: "food911_credit_used",
  },
  {
    title: "Rating",
    dataIndex: "rating",
  },
  {
    title: "Review",
    dataIndex: "review",
  },
  {
    title: "Refund",
    dataIndex: "refund",
  },
  {
    title: "Ingredients Name",
    dataIndex: "ingredients_name",
  },
  {
    title: "Ingredients Total Price",
    dataIndex: "ingredient_total_price",
  },
  {
    title: "Total Delivery Price",
    dataIndex: "totalDeliveryPrice",
  },
  {
    title: "Deduct Loyalty Points",
    dataIndex: "deduct_loyalty_points",
  },
  {
    title: "Deduct Loyalty Points Total Price",
    dataIndex: "deduct_loyalty_points_totalPrice",
  },
];

// console.log(list?.earning_details?.);

  return (
    <>
     <Header title="Orders">
     <Button
          type="default"
          style={{
            width: "138px",
            height: "41px",
            borderRadius: "10px",
            fontSize: "15px",
            fontWeight: 600,
          }}
          onClick={() => ExcelExport("Orders", list?.order_list?.data, eColumn)}

          icon={
            <img
              src={MSExcel}
              alt="excel"
              className="inline pr-3 text-sm font-normal "
              style={{
                transform: "translateY(-3px)",
              }}
            />
          }
        >
          Export
        </Button>
       </Header>
       <main className="2xl:px-12 xl:px-6 py-5">

    <BackgroundUi >
  
      <Table
        sticky={false}
        onChange={handleChange}
        
        loading={{
          spinning:isLoading,
          indicator:<TableLoading/>,
          
        }}        scroll={{ x: 3300 }}
        columns={columns}
        dataSource={list?.order_list?.data}
        pagination={{
          showSizeChanger: true,
          total:list?.order_list?.total,
          showTotal: (total) => {
     
            
            return <p className="transform translate-x-7">{total} records found</p>
           },
           size: "small",
           defaultPageSize:10,
           pageSizeOptions:['5','10','20','30', '50', '100']
           
        }}
        onRow={(record, i) => ({
          onClick: (e) => history.push(`/dashboard/order/${record?.order_id.replace(/#/g, '')}`),
        })}
     
        summary={() => (!isLoading && list?.order_list?.data.length > 0) && <Table.Summary.Row>
          {

            columns.map((dt: any, index: number) =>{
                            

              return <Table.Summary.Cell index={index} key={"Food911_Customer_Logs" + index}>
              {
                dt.title === "Food911 earning" && <div className="font-bold text-sm transform -translate-x-3">
                  &euro; {list?.earning_details?.food911_earning}
                </div>
              }
               {
                dt.title === "Refund" && <div className="font-bold text-sm transform -translate-x-3">
                  &euro; {list?.earning_details?.refund}
                </div>
              }
              {
                dt.title === "delivery_partner_earning" && <div className="font-bold text-sm transform -translate-x-3">
                  &euro; {list?.earning_details?.refund}
                </div>
              }
              {
                dt.title === "Restaurant earnings" && <div className="font-bold text-sm transform -translate-x-3">
                  &euro; {list?.earning_details?.restaurant_earning}
                </div>
              }
              {
                dt.title === "Food911 creadit used" && <div className="font-bold text-sm transform -translate-x-3">
                  &euro; {list?.earning_details?.food911_credit_used}
                </div>
              } {
                dt.title === "Delivery partner earnings" && <div className="font-bold text-sm transform -translate-x-3">
                  &euro; {list?.earning_details?.delivery_partner_earning}
                </div>
              }
              {
                dt.title === "Total final price" && <div className="font-bold text-sm text-center transform -translate-x-3">
                  &euro; {list?.earning_details?.total_final_price}
                </div>
              }
            </Table.Summary.Cell>})
          }
        </Table.Summary.Row>}


             footer={() => <hr style={{ height: "2px", padding:"0px 1px", background: "#f5f5f5" }} />}

     />

  
    </BackgroundUi>
    </main>
    </>

  );
}
