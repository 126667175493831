import React from "react";
import OrdersInfo from "./orderInfo";
import OrderTable from "./orderTable";
import Backgroudui from "../../../shared/backgroudui";
import Address from "./address";
import Comment from "./comment";
import { useFetchOne } from "../../../../api/useApihook";
import { onErrorMsg, onSuccessfetchMsg } from "../../../shared/Message";
import { useParams } from "react-router-dom";
import { selectAuth } from "../../../../global/authSlice";
import { useAppSelector } from "../../../../global/hooks";
import Loader from "../../../shared/loader";

export default function Index() {

  

 const {id} = useParams() as any
 const {user, default_restaurant_id} = useAppSelector(selectAuth);

const onError = ({response}:any) => onErrorMsg(response)
  const {data, isLoading} = useFetchOne(`order-details/${default_restaurant_id}/${id}`, onSuccessfetchMsg, onError)

  console.log({data});
  

  return (
    <div>

      <main className="2xl:px-12 xl:px-6 py-5">
     { isLoading ? <Loader/> :  <>

        <Backgroudui>
          <OrdersInfo id={id} />
          <br />
          <OrderTable  data={data}/>
        </Backgroudui>
        <br />
        <Backgroudui>
          <Address  address={data?.order_list} />
        </Backgroudui>
        <br />
        <Backgroudui>
          <Comment text={data?.order_list?.comment_for_restaurant} />
        </Backgroudui>
        </>
}
      </main>
    </div>
  );
}
