import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

export default function Backgroudui({ children, pad = "1.5rem" }: any) {
  const variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: {
      // duration: 0.1,
      type: "tween",
      ease: "easeInOut",
    },
  };
  return (
    <BackgrouduiWrap style={{ padding: `1rem ${pad}` }}>
      <div>{children}</div>
    </BackgrouduiWrap>
  );
}

const BackgrouduiWrap = styled(motion.div)`
  border-radius: 10px;
  overflow: hidden;
  background-color: white;

  .ant-pagination {
    padding-top: 20px;
    li {
      border: none;
      display: grid;
      place-items: center;
      font-size: 15px;
      font-weight: 500;
      border-radius: 7px;
      font-size: 15px;
      a {
        color: rgba(0, 0, 0, 0.5);
      }
      &:hover {
        background-color: #f5f5f5;
      }
      button {
        border: none;
        display: grid;
        place-items: center;
        transform: translateY(4px);
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
    .ant-pagination-total-text {
      position: absolute;
      left: 0;
      color: rgba(107, 114, 128.5);
      &:hover {
        background: none;
      }
    }
  }
 
  .btn {
    border: none;
    height: 24px;
    background: #e9f6ff;
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding: 0 1rem;

    text-align: center;

    color: #21a2ff;
  }

  .title {
    color: #000000;
    opacity: 0.9;
    font-weight: 600;
    font-size: 18px;
    line-height: 42px;
  }

  .card {
    padding: 1.5rem;
    border-radius: 10px;
    height: 240px;
    min-width: 220px;
  


    .date {
      transform: translateY(6px);
      font-size: 11px;
      line-height: 22px;
      color: #1e1e1e;

      opacity: 0.5;
    }

    hr {
      opacity: 0.1;
      border: 1px solid #000000;
      border-radius: 10px;
    }

    h5 {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      text-transform: uppercase;
      color: #1e1e1e;
      opacity: 0.5;
      
    }

    .nest{

      position: absolute;
      top:50%;
      left:50%;
      transform:translate(-50%, -50%);
    }
  }
`;
