import { message } from 'antd'





export function onSuccessMsg(data:any, goBack?:any) {


    

    message.config({
        duration: 0.8
    
      });


      
      
   if(data?.code == 400) {

    message.error( JSON.stringify(data?.message)).then(()=>null)

   } else {

    message.success( JSON.stringify(data?.message)).then(()=>goBack())


   }
  



}


export function onErrorMsg(response:any) {

    
    // message.config({
    //     duration: 0.4,
    
    //   });

  
    if(!response){

        return null
 
     };

    return message.error((typeof(response?.data?.message) == 'object')? JSON.stringify(response?.data?.message): response?.data?.message || 'somthing went wrong!')
}

export function onSuccessfetchMsg(data?:any) {


    return null

}

