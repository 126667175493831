import { Drawer } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useFetchAll } from "../api/useApihook";
import { updateNotificationCount } from "../global/authSlice";
import { useAppDispatch } from "../global/hooks";
import noimage from "./images/no-image.jpg";
import Truncate from "./shared/truncate";
type NotificationDrawerTypes = {
  visible: boolean;
  closeDrawer: () => void;
};
export default function NotificationDrawer({
  visible,
  closeDrawer,
}: NotificationDrawerTypes) {
  const [unreadNotifications, setunreadNotifications] = useState([]);

  const dispatch = useAppDispatch();
  const { data: notification } = useFetchAll(
    "notification-list",
    null,
    null
  );
  useEffect(() => {
    if (notification) {
      let getunreadNotifications = notification?.data.reduce(
        (finalCount: any[], current: { view_status: number }) => {
          if (current.view_status === 0) {
            finalCount.push(current);
          }
          return finalCount;
        },
        []
      );
      setunreadNotifications(getunreadNotifications);
      dispatch(updateNotificationCount(getunreadNotifications.length));
    }
  }, [dispatch, notification]);

  const notificationList = useMemo(
    () =>
      unreadNotifications?.map((item: any, i: any) => {
        return (
          <Link to={`/dashboard/notification/${item?.id}`} key={item?.id}>
            <p className="h-2" />{" "}
            <div className="rounded bg-gray-100 flex cursor-pointer items-center ">
              <img
                src={item?.notification_image || noimage}
                alt=""
                className=" w-12 h-10 object-cover rounded"
              />
              <p className=" text-md capitalize text-gray-700 font-semibold">
                &nbsp; &nbsp; {Truncate(item?.title, 30)}
              </p>
            </div>
          </Link>
        );
      }),
    [unreadNotifications]
  );

  return (
    <Drawer
      title={<h1 className="pl-2">Notification</h1>}
      placement="right"
      onClose={closeDrawer}
      visible={visible}
    >
      {unreadNotifications.length < 1 ? (
        <h1 className="text-center">No new Notification</h1>
      ) : (
        notificationList
      )}
    </Drawer>
  );
}
