import React from "react";
import { Table, Tag, Space } from "antd";
import { List, Typography, Divider } from "antd";
import styled from "styled-components";

export default function OrderTable({data}:any) {

  const { order_information, vat_amount, packing_fee,
    total_price_adding_vat_packing_fee, ingredient_total_price,
    total_delivery_price,
      order_total_price } = data?.order_list 



  const columns = [
    {
      title: "Sl. no",
      key:"sl.no",
      render:(t:any, o:any, i:any)=>{
        
return <p>{i+1}</p>
      }
    },
    {
      title: "Product Name",
      dataIndex: "item_name",
      key: "item_name",
    },
   
    {
      title: "Extra ingredients",
      dataIndex: "extra_ingredients_name",
      key: "extra_ingredients",
      render:(t:any, o:any, i:any)=>{
        
        
        return <p>{t.join(',')}</p>
              }
    },
    {
      title: "ingredient total_price",
      dataIndex: "ingredient_total_price",
      key: "ingredient_total_price",
    },
    {
      title: "size",
      dataIndex: "size",
      key: "size",
    },
    {
      title: "quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "price",
      dataIndex: "order_menu_price",
      key: "order_menu_price",
    },
    {
      title: "Order Total",
      dataIndex: "order_item_total_price",
      key: "order_item_total_price",
    },
  ];


  return (
    <OrderTableWrap>
      <Table pagination={false} columns={columns} dataSource={order_information} />
      <hr />
      <br />
      
      <div className="flex  justify-end transform translate-x-20">
        <ul>
          <li>
            <p className="item">Item Total</p>
            <p className="price">{order_total_price}</p>
          </li>
          {/* <li>
            <p className="item">Ingredients Total</p>
            <p className="price">{ingredient_total_price}</p>
          </li> */}
          <li>
            <p className="item">Packaging Charges</p>
            <p className="price">{packing_fee}</p>
          </li>
          {/* <li>
            <p className="item">Food 911 Credits</p>
            <p className="price">0</p>
          </li> */}
          <li>
            <p className="item">Taxes</p>
            <p className="price">{vat_amount}</p>
          </li> 
          <li>
            <p className="item">Total Delivery Charges</p>
            <p className="price">{total_delivery_price}</p>
          </li>
        </ul>
        <div style={{ width: "13.8%" }}></div>
      </div>
      <br />
      <div style={{ background: "#FFF9E7" }} className="py-2  flex justify-end">
        <div style={{ width: "30%" }} className="flex justify-between transform translate-x-20">
          <h2>Grand Total</h2>
          <h2 className="font-bold"> &euro;{total_price_adding_vat_packing_fee} 	</h2>
        </div>
        <div style={{ width: "13.5%" }}></div>
      </div>
    </OrderTableWrap>
  );
}

const OrderTableWrap = styled.div`
  ul {
    width: 30%;
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .price {
      font-weight: 600;
    }
    .item {
      font-size: 11px;
      line-height: 22px;
      color: #1e1e1e;

      opacity: 0.8;
    }
  }
`;
