import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router";
import { IoIosArrowBack } from "react-icons/io";
export default function OrderInfo({id}:any) {
  const { path } = useRouteMatch();
  const { goBack } = useHistory();
  // let appPath = path.split("/");
  // let a = appPath.slice(0, appPath.length - 1);
  // a.push("invoice");
  // let newPath = a.join("/");

  return (
    <>
      <div
        className="text-sm text-blue-500 pb-2 flex items-center cursor-pointer px-3"
        onClick={goBack}
      >
        <IoIosArrowBack /> &nbsp; Back
      </div>
      <div className="flex justify-between items-center">
        <div>
          <h5 className="title">#{id} Order information</h5>
        </div>
        <div>
       
            <Link to={`/dashboard/order-invoice/${id}`}>
            <Button
            type="primary"
            style={{
              width: "138px",
              height: "41px",
              borderRadius: "10px",
              fontSize: "15px",
              fontWeight: 600,
            }}
          >
              View invoice
              </Button>

              </Link>
        </div>
      </div>
    </>
  );
}
