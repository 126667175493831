import React from 'react'
import BarLoader from "react-spinners/PropagateLoader";
import logo from '../images/logo1.png'
import {Skeleton} from 'antd'
export default function TableLoading() {
  return (
    <div className='w-full h-full flex p-4 px-4  flex-col justify-center transform translate-y-6 items-center'>
{/* <img  style={{background:"#0000001f", width:'50px'}} src={logo} alt="logo" className=" p-1 rounded-md " />
<div className='h-2'/>
<BarLoader size='12px' color="#929292"  /> */}
<Skeleton 

paragraph={{ rows: 5, width:"100%" }}
  title={{
    width:"100%"
  }}
    active />
    </div>
  )
}
