import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import BellIcon from "./images/sidebar-icons/bell.png";

import AccessIcon from "./images/sidebar-icons/icon (1).png";
import OrderIcon from "./images/sidebar-icons/icon (2).png";
import PromotionIcon from "./images/sidebar-icons/icon (5).png";
import DashboardIcon from "./images/sidebar-icons/icon (8).png";
import AdditionIcon from "./images/sidebar-icons/icon (3).png";
import ResturentIcon from "./images/sidebar-icons/icon (6).png";
import MenuIcon from "./images/sidebar-icons/icon (7).png";
import PayoutIcon from "./images/sidebar-icons/icon (4).png";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useTranslation } from "react-i18next";

import logo from "./images/logo1.png";

const { Sider } = Layout;

export default function SideBar({ collapsed, click }: any) {
  const [key, setKey] = useState() as any;

  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setKey(pathname.substring(pathname.lastIndexOf("/") + 1));
  }, [pathname]);

  let access = JSON.parse(
    localStorage.getItem("restaurent") as any
  )?.assigned_permission;

  return (
    <SideWrap collapse={collapsed}>
      {key && (
        <Sider
          collapsible
          collapsedWidth={70}
          trigger={null}
          collapsed={collapsed}
          width="270"
          style={{
            // overflow: 'hiden',
            opacity: 23,
            height: "100vh",
            position: "sticky",
            top: 0,
            left: 0,
            boxShadow: "0 1px 4px -1px rgb(0 0 0 / 15%)",
            backgroundColor: "var(--acentColor)",
          }}
        >
          <div className="collapse" onClick={() => click()}>
            {collapsed ? <MdChevronRight /> : <MdChevronLeft />}{" "}
          </div>

          <div className="logo">
            <img src={logo} alt="logo" width={collapsed ? "25px" : "60px"} />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Menu
            className="menu"
            onClick={({ key }) => setKey(key)}
            style={{ border: "none" }}
            theme="light"
            mode="inline"
            defaultSelectedKeys={[key]}
          >
            {access?.["Access Management"] && (
              <Menu.Item
                key="home"
                icon={
                  <img
                    alt=""
                    src={DashboardIcon}
                    style={{ opacity: key === "home" ? 1 : 0.5 }}
                  />
                }
              >
                <Link to="/dashboard/home">{t("menu.dashboard")}</Link>
              </Menu.Item>
            )}

            {access["Menu Editor"] && (
              <Menu.Item
                key="menu-editor"
                icon={
                  <img
                    alt=""
                    src={MenuIcon}
                    style={{ opacity: key === "menu-editor" ? 1 : 0.5 }}
                  />
                }
              >
                <Link to="/dashboard/menu-editor">{t("menu.menu_editor")}</Link>
              </Menu.Item>
            )}

            {access["Restaurant Details"] && (
              <Menu.Item
                key="restaurant-details"
                icon={
                  <img
                    alt=""
                    src={ResturentIcon}
                    style={{ opacity: key === "restaurant-details" ? 1 : 0.7 }}
                  />
                }
              >
                <Link to="/dashboard/restaurant-details">
                  {t("menu.restuarant_details")}
                </Link>
              </Menu.Item>
            )}

            {access["Promotion"] && (
              <Menu.Item
                key="promotions"
                icon={
                  <img
                    alt=""
                    src={PromotionIcon}
                    style={{ opacity: key === "promotions" ? 1 : 0.5 }}
                  />
                }
              >
                <Link to="/dashboard/promotions">{t("menu.promotions")}</Link>
              </Menu.Item>
            )}

            {true && (
              <Menu.Item
                key="orders"
                icon={
                  <img
                    alt=""
                    src={OrderIcon}
                    style={{
                      paddingLeft: "6px",
                      opacity: key === "orders" ? 1 : 0.5,
                    }}
                  />
                }
              >
                <Link to="/dashboard/orders">{t("menu.orders")}</Link>
              </Menu.Item>
            )}

            {access["Additions & Deductions"] && (
              <Menu.Item
                key="additions-deductions"
                icon={
                  <img
                    alt=""
                    src={AdditionIcon}
                    style={{
                      opacity: key === "additions-deductions" ? 1 : 0.5,
                    }}
                  />
                }
              >
                <Link to="/dashboard/additions-deductions">
                  {t("menu.addition_deduction")}
                </Link>
              </Menu.Item>
            )}

            {access["Payout Reports"] && (
              <Menu.Item
                key="payout-reports"
                icon={
                  <img
                    alt=""
                    src={PayoutIcon}
                    style={{ opacity: key === "payout-reports" ? 1 : 0.5 }}
                  />
                }
              >
                <Link to="/dashboard/payout-reports">
                  {t("menu.payout_reports")}
                </Link>
              </Menu.Item>
            )}

            {access["Tags"] && (
              <Menu.Item
                key="tags"
                icon={
                  <img
                    alt=""
                    src={AccessIcon}
                    style={{ opacity: key === "tags" ? 1 : 0.5 }}
                  />
                }
              >
                <Link to="/dashboard/tags">{t("menu.tags")}</Link>
              </Menu.Item>
            )}
            {access["Access Management"] && (
              <Menu.Item
                key="access-management"
                icon={
                  <img
                    alt=""
                    src={AccessIcon}
                    style={{ opacity: key === "access-management" ? 1 : 0.5 }}
                  />
                }
              >
                <Link to="/dashboard/access-management">
                  {t("menu.access_management")}
                </Link>
              </Menu.Item>
            )}

            <Menu.Item
              key="notification"
              icon={
                <img
                  alt=""
                  src={BellIcon}
                  style={{ opacity: key === "notification" ? 1 : 0.5 }}
                />
              }
            >
              <Link to="/dashboard/notification">{t("menu.notification")}</Link>
            </Menu.Item>
          </Menu>
        </Sider>
      )}
    </SideWrap>
  );
}

const SideWrap = styled.div<{ collapse: any }>`
  z-index: 3;
  .logo {
    position: absolute;
    top: ${(props) => (props.collapse ? "6%" : "4%")};
    left: ${(props) => (props.collapse ? "32%" : "20%")};
  }
  .menu {
    img {
      padding-right: ${(props) => (props.collapse ? "0" : "1rem")};
    }
  }

  .ant-menu-item-selected {
    background-color: var(--accentColor);
    box-shadow: 0px 1px 4px rgb(0 0 0 / 2%) !important;
    font-weight: 600;
  }

  .ant-menu {
    overflow-x: hidden !important;
  }

  .ant-menu-item {
    /* font-family: 'P', sans-serif; */
    display: flex;
    align-items: center;
    /* letter-spacing:0.5px; */
    border-radius: 19.5px 0px 0px 19.5px;
    transform: ${(props) =>
      props.collapse ? "translateX(5px)" : "translateX(30px)"};
    width: ${(props) => (props.collapse ? "91%" : "89%")};
    margin: 0.5rem 0 !important;

    a {
      font-size: 13px;
      font-family: "Montserrat", sans-serif !important;
      letter-spacing: 0.2px;
    }
    svg {
      transform: translateY(-1px);
    }
    span {
      font-size: 1rem;
    }
  }

  .collapse {
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
    background-color: var(--acentColor);
    position: absolute;
    // right: ${(props) => (props.collapse ? "-22%" : "-5%")};
    right: -15.5px;
    z-index: 25;
    top: 7%;
    color: white;
    font-size: 1.6rem;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  }
`;
