import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { useAppSelector } from "./global/hooks";
import { selectAuth } from "./global/authSlice";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import ltLt from 'antd/lib/locale/lt_LT';
import enUS from 'antd/lib/locale/en_US';


import Login from "./components/auth/login";
import Dashboard from "./components";
import NotFound from "./components/pages/notFound";

import "./App.less";

function App() {
  const { isAuthenticated, lng } = useAppSelector(selectAuth);

  const queryClient = new QueryClient();
  // window.location.href = '/dashboard/home'
  return (
    <QueryClientProvider client={queryClient}>
            <ConfigProvider locale={(lng==='ru') ? ruRU : (lng==='lt')? ltLt : enUS}>

      <main className="App">
        <Router >
          <Switch>
            <Route
              path="/"
              render={() =>
                isAuthenticated  ? window.location.href = '/dashboard/home' : <Login />
              }
              exact
            />
            <Route
              path="/dashboard"
              render={() =>
                isAuthenticated ? <Dashboard /> : window.location.href = '/'
              }
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </Router>
      </main>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
