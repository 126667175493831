import { Excel } from 'antd-table-saveas-excel' ;     

export default function ExcelExport(name:string, edata:any, ecolumn:any) {

    const excel = new Excel(); 
            
   return excel.addSheet(name)
       .addColumns(ecolumn)
       .addDataSource(edata, { 
         str2Percent : true, 
       } ).saveAs(`${name}.xlsx`);
    
}
