import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState, AppThunk } from "./store";
import { asyncAuth } from "./asyncToken";
import { useHistory } from "react-router-dom";
import key from "../key";

export interface authState {
  isAuthenticated: boolean;
  status: "idle" | "loading" | "failed";
  isError: string | null;
  user: any | null;
  forgotScreen: boolean;
  lng: string;
  default_restaurant_id: String;
  restaurant_type: any;
}

let token = localStorage.getItem("api_token")
  ? localStorage.getItem("api_token")
  : null;

let user = localStorage.getItem("restaurent")
  ? JSON.parse(localStorage.getItem("restaurent") as any)
  : null;

let default_restaurant_id = localStorage.getItem("restaurent")
  ? JSON.parse(localStorage.getItem("default_restaurant_id") as any)
  : null;

let lng = localStorage.getItem("i18nextLng")
  ? (localStorage.getItem("i18nextLng") as any)
  : "en";

let restaurant_type = localStorage.getItem("restaurent")
  ? localStorage.getItem("restaurant_type" as any)
  : "food";

const initialState: authState = {
  isAuthenticated: token ? true : false,
  status: "idle",
  isError: null,
  user,
  forgotScreen: false,
  lng,
  default_restaurant_id: default_restaurant_id,
  restaurant_type: user?.restaurant_type,
};

export const isAuth = createAsyncThunk("api/asyncAuth", async () => {
  const { token, sub } = (await asyncAuth()) as any;

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      notify: sub,
    },
  };

  try {
    const { data } = await axios.get(
      key.BACK_ENDURL + "/customer/profile",
      config
    );

    return data;
  } catch ({ response }) {
    localStorage.removeItem("sfclienttoken");
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getLogin: (state) => {
      state.status = "loading";
    },
    getLoginSuccess: (state, { payload }) => {
      localStorage.setItem("api_token", payload?.api_token);
      localStorage.setItem("restaurent", JSON.stringify(payload));
      localStorage.setItem(
        "default_restaurant_id",
        JSON.stringify(payload?.default_restaurant_id)
      );
      localStorage.setItem(
        "restaurant_type",
        JSON.stringify(payload?.restaurant_type)
      );

      state.isAuthenticated = payload?.api_token ? true : false;
      state.user = payload;
      state.status = "idle";
    },

    getLoginError: (state) => {
      state.status = "idle";
    },

    getError: (state, { payload }) => {
      state.isError = payload;
      state.status = "idle";
    },
    ViewForgots: (state, { payload }) => {
      state.forgotScreen = !state.forgotScreen;
    },
    getLng: (state, { payload }) => {
      state.lng = payload;
    },
    updateNotificationCount: (state, { payload }) => {
      state.user.notificationCount = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(isAuth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(isAuth.fulfilled, (state, { payload }: any) => {
        state.status = "idle";
        state.isAuthenticated = payload ? true : false;
        state.user = payload;
      })
      .addCase(isAuth.rejected, (state, { payload }: any) => {
        state.status = "idle";
      });
  },
});

export const {
  getLogin,
  getError,
  ViewForgots,
  getLng,
  getLoginSuccess,
  getLoginError,
  updateNotificationCount
} = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
