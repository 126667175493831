import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation } from "react-query";
import axios from "axios";
import logo from "../images/logo (4).png";
import { Form, Input, Button, Checkbox, message } from "antd";
import ForgetPassword from "./forgotpass";
import Backdrop from "./backdrop";
import { useAppDispatch, useAppSelector } from "../../global/hooks";
import {
  getLoginSuccess,
  selectAuth,
  ViewForgots,
} from "../../global/authSlice";
import key from "../../key";
import AppBackDrop from "../shared/AppBackDrop";
import { onSuccessMsg } from "../shared/Message";

export default function Login() {
  const [fpScreen, toggleFPscreen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const mutation = useMutation((newTodo) => {
    return axios.post(key.BACK_ENDURL + "login", newTodo, {
      headers: {
        Accept: "application/json",
        "X-localization": "en",
      },
    });
  });



  const onFinish = (values: any) => {
    setLoading(true);

    mutation.mutate(values, {
      onSuccess: ({ data }: any) => {

      (data?.code == 200) && dispatch(getLoginSuccess(data?.data))
        setLoading(false);
      },
      onError: ({ response }: any) => {
        
        message.error(
          typeof response?.data?.message == "object"
            ? JSON.stringify(response?.data?.message)
            : response?.data?.message
            ? response?.data?.message
            : "somthing went wrong!"
        );
        setLoading(false);
      },

      onSettled: ({data}:any)=>{

        
       data?.code == 400 && message.error( JSON.stringify(data?.message))
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <LoginWrap>
      <div className="absolute login p-12">
        <img src={logo} alt="logo" width="47px" />
        <h2 className="pt-4">Welcome to Restaurant!</h2>
        <p className="subheading">Sign in to start your session</p>
        <br />

        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={<p className="label">Email address</p>}
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input className="inputfield" />
          </Form.Item>

          <Form.Item
            label={<p className="label">Password</p>}
            name="password"
            style={{ color: "#e5e5e5" }}
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password className="inputfield" />
          </Form.Item>

          <div className="flex justify-between items-top h-12">
            <Form.Item name="remember" className="" valuePropName="checked">
              <Checkbox>
                {" "}
                <p className="text-white opacity-90">Remember me</p>
              </Checkbox>
            </Form.Item>

            <h6
              // onClick={ViewForgot}
              onClick={() => toggleFPscreen(true)}
              className="mb-0 cursor-pointer"
              style={{ color: "var(--brandColor)" }}
            >
              Forgot password?
            </h6>
          </div>

          <Form.Item className=" transform translate-y-5">
            <Button
              disabled={loading ? true : false}
              style={{ height: "45px" }}
              className=" bg-gray-100 py-5 px-8   submitbtn"
              htmlType="submit"
            >
              {loading ? "Loading..." : "login"}
            </Button>
          </Form.Item>
        </Form>
      </div>

      {fpScreen && (
        <AppBackDrop close={() => toggleFPscreen(false)}>
          <ForgetPassword />
        </AppBackDrop>
      )}

      {/* {forgotScreen && <ForgetPassword />}
      {forgotScreen && <Backdrop />} */}
    </LoginWrap>
  );
}

const LoginWrap = styled.div`
  background-color: #e5e5e5;

  .login {
    width: 507px;
    min-height: 545px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #1e1e1e;
    border-radius: 20px;

    .submitbtn {
      display: block !important;
      margin: auto !important;
      width: 40%;
      font-weight: bolder;
      color: #000000;
      font-size: 15px;
      border-radius: 8px;
    }

    h2 {
      font-size: 28px;
      font-weight: 600;
      line-height: 42px;
      color: #ffffff;
      opacity: 0.9;
    }

    .subheading {
      font-size: 15px;
      color: #ffffff;
      opacity: 0.7;
    }

    .label {
      color: #ffffff;
      opacity: 0.4;
      line-height: 52px;
      font-size: 12px;
      margin: 0 !important;
    }

    .inputfield {
      background-color: var(--acentColor);
      border: none;
      border-bottom: 2px solid #ffffff3e !important;
      color: #ffffff !important;

      input {
        background-color: var(--acentColor);
        color: #ffffff !important;
      }
    }
  }
`;
