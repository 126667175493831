import React from "react";
import { Table} from "antd";
import BackgroundUi from "../../../shared/backgroudui";
import styled from "styled-components";

export function RollTable({data}:any) {

  console.log({data});
  

  const columns = [
    {
      title: () => <p className="text-left">Item</p>,
      dataIndex: "item_name",
      key: "item_name",
      render: (text: any) => {
        return <p className="grid place-items-start">&nbsp;{text}</p>;
      },
    },
    {
      title: () => <p className="text-center">Price</p>,
      dataIndex: "order_menu_price",
      key: "order_menu_price",
      render: (text: any) => {
        return <p className="grid place-items-center">{text}</p>;
      },
    },
  
    {
      title: () => <p className="text-center ">Quantity</p>,
      dataIndex: "quantity",
      key: "quantity",
      render: (text: any, t:any) => {
        console.log(t);
        
        return <p className="grid place-items-center">{text}</p>;
      },
    },
    // {
    //   title: () => <p className="text-center ">VAT</p>,
    //   dataIndex: "vat_amount",
    //   key: "vat_amount",
    //   render: (text: any) => {
    //     return <p className="grid place-items-center">{text}</p>;
    //   },
    // },
    {
      title: () => <p className="text-right">Total</p>,
      dataIndex: "order_total_price",
      key: "order_total_price",
      render: (text: any, t:any) => {
        
        return <p className="grid place-items-end">&nbsp;{t?.order_item_total_price}</p>;
      },
    },
  ];

  return (
    <BackgroundUi pad="0">
      <Table columns={columns} dataSource={data?.order_invoice_data?.order_information} pagination={false} />
      <hr />
      <TableListWrapper>
      <li className="flex justify-between py-2 font-medium">
              <p>Total Delivery Price</p>
              <p>{data?.order_invoice_data?.total_delivery_price}</p>
            </li>
        <li className="flex justify-between py-2 font-medium">
          <p>Subtotal excl. VAT</p>
          <p>{data?.order_invoice_data?.order_total_price}</p>
        </li>
        <li className="flex justify-between py-2 font-medium hinter">
          <p>VAT </p>
          <p>{data?.order_invoice_data?.vat_amount}</p>
        </li>
        <li className="flex justify-between py-3 font-semibold tborder">
          <p>Total in EUR</p>
          <p>&euro;{data?.order_invoice_data?.total_price_adding_vat_packing_fee}</p>
        </li>
      </TableListWrapper>
    </BackgroundUi>
  );
}

export const TableListWrapper = styled.ul`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  .hinter {
    padding-bottom: 15px;
  }
  .tborder {
    padding-top: 10px;
    border-top: 2px solid gray;
    border-bottom: 2px solid gray;
  }
`;
