import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import {
  Route,
  Switch,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import Order from "./pages/order";
import OrderInfo from "./pages/order/orderInfo";
import Invoice from "./pages/order/invoice";
import { Layout } from "antd";
import styled from "styled-components";
import SideBar from "./sideBar";
import Loader from "./shared/loader";


const Dashboard = React.lazy(()=>import("./pages/dashboard"))
const RestaurantDetail = React.lazy(()=>import("./pages/restaurentDetails"))
const Promotions = React.lazy(()=>import("./pages/Promotions"))
const AddCategory = React.lazy(()=>import("./pages/MenuEditor/category/AddCategory"))
const AddItem = React.lazy(()=>import("./pages/MenuEditor/menu/AddItem"))
const MenuEditor = React.lazy(()=>import("./pages/MenuEditor"))
const AddTag = React.lazy(()=>import("./pages/tags/AddTag"))
const AddPromotion = React.lazy(()=>import("./pages/Promotions/AddPromotion"))
const EditPromotion = React.lazy(()=>import("./pages/Promotions/EditPromotion"))
const EditCategory = React.lazy(()=>import("./pages/MenuEditor/category/EditCategory"))
const EditItem = React.lazy(()=>import("./pages/MenuEditor/menu/EditItem"))
const AdditionsDeductions = React.lazy(()=>import("./pages/AdditionsDeductions"))
const PayoutOrders = React.lazy(()=>import("./pages/PayoutOrders"))
const AccessManagement = React.lazy(()=>import("./pages/AccessManagement"))
const AddNewUser = React.lazy(()=>import("./pages/AccessManagement/AddNewUser"))
const EditUser = React.lazy(()=>import("./pages/AccessManagement/EditUser"))
const Tags = React.lazy(()=>import("./pages/tags/Tags"))
const EditTag = React.lazy(()=>import("./pages/tags/EditTag"))
const Food911Details = React.lazy(()=>import("./pages/food911details"))
const ChangePassword = React.lazy(()=>import("./pages/changePassword"))
const Notification = React.lazy(()=>import("./pages/notifications"))
const NotificationView = React.lazy(()=>import("./pages/notifications/view"))

const Profile = React.lazy(()=>import("./pages/Profile"))
// const Pay = React.lazy(()=>import("./pages/payable-pay"))



const { Content, Footer } = Layout;

export default function DashboardIndex() {
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  let { path } = useRouteMatch();
  const location = useLocation();

  return (
    <AdminWrap>
      <Layout>
        {/* <Affix offsetTop={0}> */}
        <SideBar click={toggle} collapsed={collapsed} color={false} />
        {/* </Affix> */}
        <Layout className="site-layout">
          {/* <Header click={toggle} collapsed={collapsed}/> */}

          <Content
            className=""
            style={{
              minHeight: "85vh",
              backgroundColor: "#F5f5f5",
            }}
          >

              <Switch location={location} key={location.pathname}>
                <React.Suspense fallback={<Loader/>}>
                <Route exact path={`${path}/home`} component={Dashboard} />

                <Route
                  exact
                  path={`${path}/restaurant-details`}
                  component={RestaurantDetail}
                />
             
                <Route
                  exact
                  path={`${path}/menu-editor`}
                  component={MenuEditor}
                />
                <Route
                  exact
                  path={`${path}/menu-editor/add-category`}
                  component={AddCategory}
                />
                <Route
                  path={`${path}/menu-editor/add-item`}
                  component={AddItem}
                />
                <Route
                  exact
                  path={`${path}/tags`}
                  component={Tags}
                />
                <Route
                  exact
                  path={`${path}/tags/add-tag`}
                  component={AddTag}
                />
                <Route
                  exact
                  path={`${path}/tags/edit-tag/:id`}
                  component={EditTag}
                />
                <Route
                  path={`${path}/menu-editor/edit-category/:id`}
                  component={EditCategory}
                />
                <Route
                  path={`${path}/menu-editor/edit-item/:id`}
                  component={EditItem}
                />

             
                <Route
                  exact
                  path={`${path}/promotions`}
                  component={Promotions}
                />
                <Route
                  exact
                  path={`${path}/promotions/edit-promotion/:id`}
                  component={EditPromotion}
                />
                <Route
                  exact
                  path={`${path}/promotions/add-promotion`}
                  component={AddPromotion}
                />
                <Route
                  exact
                  path={`${path}/order-invoice/:id`}
                  component={Invoice}
                />
                <Route path={`${path}/order/:id`} component={OrderInfo} />
                <Route exact path={`${path}/orders`} component={Order} />

                <Route
                  exact
                  path={`${path}/additions-deductions`}
                  component={AdditionsDeductions}
                />
                <Route
                  exact
                  path={`${path}/payout-reports`}
                  component={PayoutOrders}
                />
                <Route
                  exact
                  path={`${path}/access-management`}
                  component={AccessManagement}
                />
                <Route
                  exact
                  path={`${path}/access-management/add-new-user`}
                  component={AddNewUser}
                />

              <Route
                  exact
                  path={`${path}/access-management/edit-new-user/:id`}
                  component={EditUser}
                />
   <Route
                  exact
                  path={`${path}/food911details`}
                  component={Food911Details}
                />
           
           <Route
                  exact
                  path={`${path}/profile`}
                  component={Profile}
                />

               <Route
                  exact
                  path={`${path}/change-password`}
                  component={ChangePassword}
                />

<Route
                  exact
                  path={`${path}/notification`}
                  component={Notification}
                />

<Route
                  exact
                  path={`${path}/notification/:id`}
                  component={NotificationView}
                />
                </React.Suspense>
              </Switch>

          </Content>

          <Footer style={{ textAlign: "center" }}>
            {" "}
           <p className="text-md font-bold">Copyright © 2022 Food911 All rights reserved.</p> 
          </Footer>
        </Layout>
      </Layout>
    </AdminWrap>
  );
}

const AdminWrap = styled.div``;
