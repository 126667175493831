import React from "react";
import { Table, Tag, Space, Button, Pagination } from "antd";
import BackgroundUi from "../../../shared/backgroudui";

export default function OrdersTable({data}:any) {


  

  const columns = [
    {
      title: () => <p className="text-left">Venue</p>,
      dataIndex: "venue",
      key: "venue",
      render: (text: any) => {
        return <p className="grid place-items-start">&nbsp;{text}</p>;
      },
    },
    {
      title: () => <p className="text-center">Order type</p>,
      dataIndex: "order_delivery_type",
      key: "order_delivery_type",
      render: (text: any) => {
        return <p className="grid place-items-center">{text}</p>;
      },
    },
    {
      title: () => <p className="text-center">Delivery time</p>,
      dataIndex: "order_delivery_time",
      key: "order_delivery_time",
      render: (text: any) => {
        return <p className="grid place-items-center">{text}</p>;
      },
    },
  
    {
      title: () => <p className="text-right">Total price in EUR</p>,
      dataIndex: "grand_total",
      key: "grand_total",
      render: (text: any) => {
        return <p className="grid place-items-end">&nbsp;&euro;{text}
        </p>;
      },
    },
  ];

  let listdata = [

{   
   venue:data?.order_invoice_data?.city,
   order_delivery_type:data?.order_invoice_data?.order_delivery_type,
   order_delivery_time:data?.order_invoice_data?.order_delivery_time,
  grand_total : data?.order_invoice_data?.total_price_adding_vat_packing_fee
  } 

]


  return (
    <BackgroundUi pad="0">
      <Table columns={columns} dataSource={listdata} pagination={false} />
    </BackgroundUi>
  );
}
