import React, { useState } from "react";
import ReactDom from "react-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useMutation } from "react-query";
import axios from "axios";

import { Form, Input, Button, Checkbox, message } from "antd";
import styled from "styled-components";
import logo from "../images/ResetPassword.svg";
import closeIcon from "../images/close.svg";
import OtpInput from "react-otp-input";
import key from "../../key";
import { useAppDispatch } from "../../global/hooks";
import { ViewForgots } from "../../global/authSlice";

export default function ForgetPassword(props: any) {
  const modal = document.getElementById("modal")!;

  const [formView, setFormView] = useState("email") as any;
  const [otpValue, setotpValue] = useState("") as any;
  const [loading, setLoading] = useState(false) as any;
  const [email, setEmail] = useState("") as any;

  const headers = {
    headers: {
      Accept: "application/json",
      "X-localization": "en",
    },
  };

  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const mutationEmail = useMutation((email) => {
    return axios.post(key.BACK_ENDURL + "forgot-password", email, headers);
  });

  const mutationOtp = useMutation((otp: any) => {
    return axios.post(key.BACK_ENDURL + "verify-otp", otp, headers);
  });

  const onFinish = (values: any) => {
    setLoading(true);

    if (values.new_password !== values.confirm_password) {
      setLoading(false);
      return message.error("password not matching!");
    }

    switch (formView) {
      case "email":
        mutationEmail.mutate(values, {
          onSettled: ({ data }: any) => {
            if(data?.code == 400) {

              message.error(JSON.stringify(data?.message))
            } else {

               data?.status === "success" && setFormView("otp");
            setEmail(data?.data?.email);
             message.success(JSON.stringify(data?.message))

            setLoading(false);
            }
           
          },
          onError: ({ response }: any) => {
            setLoading(false);

            message.error((typeof(response?.data?.message) == 'object')? JSON.stringify(response?.data?.message): response?.data?.message);
          }
        
        });

        break;
      case "otp":
        setFormView("newPassword");
        setLoading(false);

        break;
      case "newPassword":
        let data_password = {
          otp: otpValue,
          new_password: values.new_password,
          confirm_password: values.confirm_password,
          email: email,
        };

        mutationOtp.mutate(data_password, {
          onSettled: ({ data }: any) => {
            setEmail(data?.data?.email);

            if(data?.code == 400) {

              message.error( JSON.stringify(data?.message))
              setLoading(false);

            } else {

              setLoading(false);

              message.success(data?.message);
              form.resetFields();
              props.close();
              dispatch(ViewForgots(""));

            }
           
          },
          onError:({response}:any)=>{

            message.success(response?.data?.message)
            setLoading(false);
            props.close();
          }
        });
        break;
    }
  };
  return (
    <AnimatePresence>
      <ContainerWrap
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.36, type: "tween", ease: "easeInOut" }}
      >
        <div
          className="grid place-items-center grid-cols-2 gap-0 "
          style={{
            width: "918px",
            height: "601px",
            borderRadius: "25.5px",
            overflow: "hidden",
          }}
        >
          <div
            className="text-white h-full w-full flex justify-center flex-col p-12"
            style={{ background: "#1E1E1E" }}
          >
            <img
              src={logo}
              alt=""
              style={{
                height: "110px",
                position: "absolute",
                top: "70px",
                alignSelf: "start",
              }}
            />
            <h3
              className="text-white font-bold"
              style={{ fontSize: "20px", opacity: "0.9" }}
            >
              Forgot Your Password?
            </h3>
            <p className="opacity-50">
              Follow the steps and recover your password.
            </p>
            <ol
              className="list-decimal pl-4 pt-10 grid gap-2"
              style={{ fontSize: "11px" }}
            >
              <li>Enter your registered email address.</li>
              <li>Enter the OTP received on the provided email address.</li>
              <li>Enter the OTP in the text field.</li>
              <li>Enter your new password.</li>
              <li>
                Click on 'Submit' button and login with your new password.
              </li>
            </ol>
          </div>
          <div className="bg-white h-full w-full flex justify-center items-center flex-col p-12 relative">
            <span
              style={{ position: "absolute", top: "26.43px", right: "26.64px" }}
            >
              <img
              alt=""
                src={closeIcon}
                className="cursor-pointer"
                // onClick={() => dispatch(ViewForgots(""))}
                onClick={props.close}
              />
            </span>
            <div className="insideView">
              <h5 className="font-semibold">
                {formView === "email" && "Enter your registered email address"}
                {formView === "otp" && "Enter 6-digit OTP"}
                {formView === "newPassword" && "Set password"}
              </h5>
              <Form
                form={form}
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
              >
                {formView === "email" && (
                  <Form.Item
                    name="email"
                    label={<p className="label">email</p>}
                  >
                    <Input className="inputfield" />
                  </Form.Item>
                )}
                {formView === "otp" && (
                  <Form.Item>
                    <OtpInput
                      value={otpValue}
                      onChange={(otp: any) => setotpValue(otp)}
                      numInputs={6}
                      separator={<span>&nbsp;&nbsp;</span>}
                      inputStyle={{
                        borderBottom: "1px solid rgba(0,0,0,.2)",
                        outline: "none",
                        fontWeight: 500,
                        width: "40px",
                      }}
                    />
                  </Form.Item>
                )}

                {formView === "newPassword" && (
                  <>
                    <Form.Item
                      label={<p className="label">Enter new password</p>}
                      name="new_password"
                      rules={[
                        {
                          required: true,
                          message: `Please input new password!`,
                        },
                      ]}
                    >
                      <Input
                        className="inputfield tracking-widest font-black"
                        type="password"
                      />
                    </Form.Item>
                    <Form.Item
                      label={<p className="label">Re-enter password</p>}
                      name="confirm_password"
                      rules={[
                        {
                          required: true,
                          message: `Re-enter new password!`,
                        },
                      ]}
                    >
                      <Input
                        className="inputfield tracking-widest font-black"
                        type="password"
                      />
                    </Form.Item>{" "}
                  </>
                )}

                <Form.Item>
                  <Button
                    className="py-5 px-8 submitbtn absolute"
                    htmlType="submit"
                    disabled={loading ? true : false}
                  >
                    {loading ? "loading..." : "Submit"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </ContainerWrap>
    </AnimatePresence>
  );
}

const ContainerWrap = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 22;

  .insideView {
    h3 {
      font-size: 14px;
    }
    form {
      min-width: 320px;
    }
    .label {
      color: #000000;
      opacity: 0.5;
      line-height: 52px;
      font-size: 12px;
      margin: 0 !important;
    }
    .inputfield {
      border: none;
      border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
      color: #000000 !important;
      padding-left: 2px;
    }
    .submitbtn {
      background: #1d8aef;
      color: #ffffff;
      border-radius: 8px;
      width: 181px;
      height: 51px;
      font-weight: 700;
      font-size: 15px;
      top: 30px;
    }
  }
`;
