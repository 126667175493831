import React from 'react'

export default function Comment({text}:any) {
    return (
        <div>
            <h3 className="title">Comment from Restaurant</h3>
          {text? text : 'NA'}  
        </div>
    )
}
