import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from "react-router";
import Header from "../../../header";
import BackgroundUi from "../../../shared/backgroudui";
import OrdersTable from "./OrdersTable";
import { useFetchOne } from "../../../../api/useApihook";
import { onErrorMsg, onSuccessfetchMsg } from "../../../shared/Message";
import { useParams } from "react-router-dom";
import { selectAuth } from "../../../../global/authSlice";
import { useAppSelector } from "../../../../global/hooks";
import Loader from "../../../shared/loader";

import { RollTable, TableListWrapper } from "./RollTable";
export default function Index() {
  const { goBack } = useHistory();

  const {id} = useParams() as any
  const { default_restaurant_id} = useAppSelector(selectAuth);
 
 const onError = ({response}:any) => onErrorMsg(response)
   const {data, isLoading} = useFetchOne(`order-invoice-details/${default_restaurant_id}/${id}`, onSuccessfetchMsg, onError) as any
 
   console.log({data});
   

   const { customer_information,
    order_total_price,
    packing_fee,
    vat_amount,
    totalDeliveryPrice,
    order_placed,
    total_price_adding_vat_packing_fee, restaurant_name,
    restaurant_information, start_date, start_time, order_id} = !isLoading && data?.order_invoice_data

  return (
    <div>
      <Header title="Orders" />
      <main className="2xl:px-12 xl:px-6 py-5">
        <BackgroundUi pad="0">
        { isLoading ? <Loader/> :  <>
          <div className="w-full">
            <div
              className="text-sm text-blue-500 pb-2 flex items-center cursor-pointer px-3"
              onClick={goBack}
            >
              <IoIosArrowBack /> &nbsp; Back
            </div>
            <div className="py-5 px-3">
              <h5 className="text-xl font-normal">Customer Invoice</h5>
              <h3 className="text-xl font-bold">Receipt {order_id}</h3>
            </div>
          </div>
          <div className="bg-gray-200 py-2 px-3">
            <h5 className="font-semibold text-lg">Order Details</h5>
          </div>
          <div className="w-full">
            <OrdersTable  data={data} />
          </div>

          <div className="bg-gray-200 py-2 px-3 flex items-center gap-10">
            <h5 className="font-semibold text-lg">{restaurant_name}</h5>
            <p className="text-sm font-medium">{order_placed}</p>
          </div>

          <div className="w-full">
            <RollTable data={data}/>
            <br />
            <div className="flex flex-col gap-5">
              <div className="text-xs px-3">
                <h3 className="font-semibold text-lg">Seller Details</h3>
                <p>Date and time: {start_date}&nbsp; {start_time}</p>
                <p>Order ID: {order_id}</p>
                <p>Invoice ID:2343453</p>
              </div>
              <div className="text-xs px-3">
                <h3 className="font-semibold text-lg">
                  Seller: UAB AS SERVICE
                </h3>
                <p>Business ID:&nbsp;{restaurant_information?.company_code} </p>
                <p>VAT ID:&nbsp; {restaurant_information?.vat_code}</p>
                <p>Address:&nbsp; {restaurant_information?.address} &nbsp; {data?.order_invoice_data?.restaurant_information?.city}</p>
              </div>
              <div className="text-xs px-3">
                <h3 className="font-semibold text-lg">Customer: {customer_information?.name}</h3>
                <p>Payment method: Google Pay</p>
                <p>
                  Receipt delivered by Food911
                </p>
              </div>
            </div>
          </div>
          <div className="pt-8">
            <div className="bg-gray-200 py-2 px-3 flex items-center gap-10">
              <h5 className="font-semibold text-lg">
                Food 911 Delivery Receipt
              </h5>
              <p className="text-sm font-medium">{order_placed}</p>
            </div>
          </div>
          <TableListWrapper>
            <li className="flex justify-between py-2 font-medium">
              <p>Item</p>
              <p>Total</p>
            </li>
            <hr />
            <li className="flex justify-between py-2 font-medium">
              <p>
                Delivery Packing:
              </p>
              <p>{packing_fee}</p>
            </li>
            <hr />
            <li className="flex justify-between py-2 font-medium">
              <p>Total Delivery Price</p>
              <p>{totalDeliveryPrice}</p>
            </li>
            <li className="flex justify-between py-2 font-medium">
              <p>Subtotal excl. VAT</p>
              <p>{order_total_price}</p>
            </li>
            <li className="flex justify-between py-2 font-medium hinter">
              <p>VAT </p>
              <p>{vat_amount}</p>
            </li>
            <li className="flex justify-between py-3 font-semibold tborder">
              <p>Total in EUR</p>
              <p>&euro;{total_price_adding_vat_packing_fee}</p>
            </li>
          </TableListWrapper>
          <div className="w-full pb-10">
            <br />
            <div className="flex flex-col gap-5">
            <div className="text-xs px-3">
                <h3 className="font-semibold text-lg">Seller Details</h3>
                <p>Date and time: {start_date}&nbsp; {start_time}</p>
                <p>Order ID: {order_id}</p>
                <p>Invoice ID:2343453</p>
              </div>
              <div className="text-xs px-3">
                <h3 className="font-semibold text-lg">
                  Seller: UAB AS SERVICE
                </h3>
                <p>Business ID:&nbsp;{restaurant_information?.company_code} </p>
                <p>VAT ID:&nbsp; {restaurant_information?.vat_code}</p>
                <p>Address:&nbsp; {restaurant_information?.address} &nbsp; {data?.order_invoice_data?.restaurant_information?.city}</p>
              </div>
              <div className="text-xs px-3">
                <h3 className="font-semibold text-lg">Customer: {customer_information?.name}</h3>
                <p>Payment method: Google Pay</p>
                <p>
                  Receipt delivered by Food911.
                </p>
              </div>
            </div>
          </div>
          </>}
        </BackgroundUi>
      </main>
    </div>
  );
}
