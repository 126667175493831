import key from "../key";
import axios from "axios";

import { useQuery, useMutation, QueryClient } from 'react-query'

const token = localStorage.getItem("api_token") || ''


const api = axios.create({
    baseURL: key.BACK_ENDURL,
    
   });

 api.defaults.headers.common['Authorization'] = token;
 api.defaults.headers.common['X-localization'] = 'en';
// api.defaults.headers.common['Cache-Control'] = 'no-cache';
// api.defaults.headers.common['Pragma'] = 'no-cache';
// api.defaults.headers.common['Expires'] = '0';

// const queryClient = new QueryClient()

//  const getOne = (resource: string, id: any = null) =>api.get(`/${resource}?timestamp=${new Date().getTime()}`)
 const getOne = (resource: string, id: any = null) =>api.get(`/${resource}`)
 const getAll = (resource: any) =>api.get(`/${resource}`)
 const getUpdate = (resource: string, newdata: any) =>api.put(`/${resource}`, newdata)

 const getCreate = (resource: string, data: any) =>api.post(`/${resource}`, data)
 const getDelete = (resource: string) =>api.delete(`/${resource}`)


export const useFetchAll = (url:any, onSuccess:any, onError:any, extraOptions?:any) =>{

  

  return useQuery(url, ()=>getAll(url), {onSuccess, onError, select:({data}:any)=>data?.data, enabled:extraOptions
} ) 

}

export const useCreate = (url:any,  onSuccess:any, onError:any) =>{

  return useMutation((data)=>getCreate(url, data), {onSettled:onSuccess, onError} )

}

export const useFetchOne = (url:any, onSuccess:any, onError:any, extraOptions?:any) =>{


    return useQuery(url, ()=>getOne(url), {onSuccess, onError, select:({data}:any)=>data?.data,
    ...extraOptions,

  } ) as any
  
}

  

  
export const useDelete = (url:any,  onSuccess:any, onError:any) =>{

  
    return useMutation((id)=>getDelete(url+ id), {onSuccess, onError} )
  
  
  }

  